import { Formik } from "formik";
import { getPhotoSchema } from "validations";

import { useSubmitForm } from "hooks/useSubmitform";
import FileUpload from "components/FileUpload";
import { CdlScreenRoot } from "styles/styles";

const CdlScreen = ({ onNextStep, customer }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "sendPhoto",
    path: "/form/6",
    callback: () => onNextStep(),
  });

  const handleSubmitPhoto = ({ photo }) => {
    handleSubmit({
      customerId: customer.customerId,
      photo,
    });
  };

  return (
    <CdlScreenRoot>
      <h1 className="title uppercase">Upload your CDL</h1>
      <p className="subtitle">
        For your application form we need your commertial driver’s license
      </p>

      <Formik
        initialValues={{
          photo: null,
        }}
        validationSchema={getPhotoSchema()}
        onSubmit={handleSubmitPhoto}
      >
        {({ values, setFieldValue, setFieldTouched, handleSubmit }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <FileUpload
              type="file"
              file={values.photo}
              name="photo"
              errorSubmit={error}
              loadingSubmit={loading}
              onChange={(e) => {
                setFieldTouched("photo", true);
                setFieldValue("photo", e.target.files[0]);
              }}
            />
          </form>
        )}
      </Formik>
    </CdlScreenRoot>
  );
};

export default CdlScreen;
