import { AdvatangesRoot } from "styles/styles";

const Advantages = () => {
  return (
    <AdvatangesRoot>
      <div className="col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          className="adv-icon"
        >
          <path d="M11.25 8.04004H6V9.54004H11.25V8.04004Z" fill="black" />
          <path
            d="M21.3106 6.54004L17.5606 2.79004L16.5 3.85061L18.75 6.10061V9.54004C18.7505 9.93772 18.9086 10.319 19.1898 10.6002C19.4711 10.8814 19.8523 11.0396 20.25 11.04V18.915C20.25 19.2134 20.1315 19.4996 19.9205 19.7105C19.7095 19.9215 19.4234 20.04 19.125 20.04C18.8266 20.04 18.5405 19.9215 18.3295 19.7105C18.1185 19.4996 18 19.2134 18 18.915V12.54C18 12.3411 17.921 12.1504 17.7803 12.0097C17.6397 11.8691 17.4489 11.79 17.25 11.79H14.25V4.29004C14.25 4.09113 14.171 3.90036 14.0303 3.75971C13.8897 3.61906 13.6989 3.54004 13.5 3.54004H3.75C3.55109 3.54004 3.36032 3.61906 3.21967 3.75971C3.07902 3.90036 3 4.09113 3 4.29004V20.04H1.5V21.54H15.75V20.04H14.25V13.29H16.5V18.84C16.4912 19.4515 16.6923 20.0474 17.0697 20.5286C17.4471 21.0097 17.9781 21.3469 18.5741 21.4839C18.9569 21.5652 19.3531 21.56 19.7337 21.4687C20.1143 21.3774 20.4698 21.2023 20.7741 20.9562C21.0784 20.71 21.3239 20.399 21.4928 20.0459C21.6616 19.6928 21.7495 19.3064 21.75 18.915V7.60084C21.75 7.40383 21.7112 7.20875 21.6358 7.02674C21.5605 6.84472 21.45 6.67934 21.3106 6.54004ZM12.75 20.04H4.5V5.04004H12.75V20.04Z"
            fill="black"
          />
        </svg>
        <div className="mark-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M5 11.54L0 6.65115L2.33333 4.45115L5 7.05856L11.75 0.540039L14 2.82152L5 11.54Z"
              fill="#258D56"
            />
          </svg>
        </div>
        <div className="text">
          Fuel <br /> discounts
        </div>
      </div>

      <div className="col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          className="adv-icon"
        >
          <path
            d="M18.3607 8.28993C18.6258 8.68661 18.8519 9.10527 19.0372 9.54004H18.75V15.54H21.75V9.54004H20.4853C20.2371 8.83794 19.9014 8.16568 19.4832 7.53991C18.4943 6.05987 17.0887 4.90632 15.4442 4.22513C13.7996 3.54394 11.99 3.36571 10.2442 3.71298C8.49836 4.06024 6.89472 4.91741 5.63604 6.17608C4.68312 7.129 3.96033 8.27966 3.51472 9.54004H2.25V15.54H3.51472C3.56749 15.6893 3.62429 15.8374 3.68509 15.9842C4.36628 17.6287 5.51983 19.0343 6.99987 20.0233C7.62564 20.4414 8.2979 20.7771 9 21.0253V21.54H13.5V19.29H9V19.5773C8.56523 19.3919 8.14657 19.1658 7.74989 18.9008C6.51365 18.0748 5.54539 16.9067 4.96278 15.54H5.25V9.54004H4.96278C5.34449 8.64466 5.89553 7.82578 6.59064 7.13068C7.66051 6.0608 9.02361 5.33221 10.5076 5.03704C11.9915 4.74186 13.5297 4.89335 14.9275 5.47236C16.3254 6.05137 17.5202 7.03189 18.3607 8.28993Z"
            fill="black"
          />
        </svg>
        <div className="mark-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M5 11.54L0 6.65115L2.33333 4.45115L5 7.05856L11.75 0.540039L14 2.82152L5 11.54Z"
              fill="#258D56"
            />
          </svg>
        </div>
        <div className="text">
          24/7 <br /> services
        </div>
      </div>

      <div className="col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="25"
          viewBox="0 0 22 25"
          fill="none"
          className="adv-icon"
        >
          <path
            d="M17.75 8.04004L18.9447 10.29L21.5 10.6005L19.625 12.2903L20 14.79L17.75 13.3838L15.5 14.79L15.875 12.2903L14 10.6005L16.625 10.29L17.75 8.04004Z"
            fill="black"
          />
          <path
            d="M15.5 23.04H14V19.29C13.9988 18.2958 13.6034 17.3427 12.9004 16.6397C12.1973 15.9367 11.2442 15.5412 10.25 15.54H5.75C4.7558 15.5412 3.80265 15.9367 3.09964 16.6397C2.39664 17.3427 2.00117 18.2958 2 19.29V23.04H0.5V19.29C0.501628 17.8982 1.05527 16.5637 2.03949 15.5795C3.0237 14.5953 4.35811 14.0417 5.75 14.04H10.25C11.6419 14.0417 12.9763 14.5953 13.9605 15.5795C14.9447 16.5637 15.4984 17.8982 15.5 19.29V23.04Z"
            fill="black"
          />
          <path
            d="M8 3.54004C8.74168 3.54004 9.4667 3.75997 10.0834 4.17203C10.7001 4.58408 11.1807 5.16975 11.4645 5.85498C11.7484 6.5402 11.8226 7.2942 11.6779 8.02163C11.5333 8.74906 11.1761 9.41724 10.6517 9.94169C10.1272 10.4661 9.45902 10.8233 8.73159 10.968C8.00416 11.1127 7.25016 11.0384 6.56494 10.7546C5.87971 10.4708 5.29404 9.99011 4.88199 9.37343C4.46993 8.75674 4.25 8.03172 4.25 7.29004C4.25 6.29548 4.64509 5.34165 5.34835 4.63839C6.05161 3.93513 7.00544 3.54004 8 3.54004ZM8 2.04004C6.96165 2.04004 5.94661 2.34795 5.08326 2.92482C4.2199 3.5017 3.54699 4.32164 3.14963 5.28095C2.75227 6.24026 2.6483 7.29586 2.85088 8.31426C3.05345 9.33266 3.55346 10.2681 4.28769 11.0023C5.02191 11.7366 5.95738 12.2366 6.97578 12.4392C7.99418 12.6417 9.04978 12.5378 10.0091 12.1404C10.9684 11.743 11.7883 11.0701 12.3652 10.2068C12.9421 9.34342 13.25 8.32839 13.25 7.29004C13.25 5.89765 12.6969 4.56229 11.7123 3.57773C10.7277 2.59316 9.39239 2.04004 8 2.04004Z"
            fill="black"
          />
        </svg>
        <div className="mark-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M5 11.54L0 6.65115L2.33333 4.45115L5 7.05856L11.75 0.540039L14 2.82152L5 11.54Z"
              fill="#258D56"
            />
          </svg>
        </div>
        <div className="text">
          Professional <br /> dispatcher
        </div>
      </div>

      <div className="col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          className="adv-icon"
        >
          <path
            d="M22 6.02051H2V14.2445H22V6.02051Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M6.37783 19.0574C7.29196 19.0574 8.033 18.3164 8.033 17.4022C8.033 16.4881 7.29196 15.7471 6.37783 15.7471C5.4637 15.7471 4.72266 16.4881 4.72266 17.4022C4.72266 18.3164 5.4637 19.0574 6.37783 19.0574Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M11.3856 19.0574C12.2998 19.0574 13.0408 18.3164 13.0408 17.4022C13.0408 16.4881 12.2998 15.7471 11.3856 15.7471C10.4715 15.7471 9.73047 16.4881 9.73047 17.4022C9.73047 18.3164 10.4715 19.0574 11.3856 19.0574Z"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M20.6211 15.9703V13.9502"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M4.06766 14.2939L2.69141 16.4575"
            stroke="black"
            strokeMiterlimit="10"
          />
          <path
            d="M13.7617 14.2939L15.141 16.3629"
            stroke="black"
            strokeMiterlimit="10"
          />
        </svg>
        <div className="mark-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M5 11.54L0 6.65115L2.33333 4.45115L5 7.05856L11.75 0.540039L14 2.82152L5 11.54Z"
              fill="#258D56"
            />
          </svg>
        </div>
        <div className="text">
          Any Trailer <br /> Type
        </div>
      </div>

      <div className="line"></div>
    </AdvatangesRoot>
  );
};

export default Advantages;
