import styled from "styles";
import markIcon from "assets/icons/check-mark-icon.svg";

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  select {
    width: 100%;
    height: 5rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
    color: #121316;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 0.4rem;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
  }
  .select-arrow {
    position: absolute;
    top: 50%;
    right: 2.1rem;
    transform: translateY(-50%);
  }
`;
export const AutocompleteWrap = styled.div`
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 4rem;
    padding: 1.05rem 0;
    font-size: 1.6rem;
    letter-spacing: -0.015em;
    color: #121316;
    caret-color: ${(props) => `${props.theme.mainColor}`};
    border-bottom: 1px solid #e5e5e5;
    transition: 0.2s ease-in-out;
    &::placeholder {
      color: #787883;
      opacity: 1;
    }
    &:focus {
      border-color: #121316;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #121316 !important;
    }
  }
`;

export const ShareExpScreenRoot = styled.div`
  input[type="radio"] {
    margin: 0;
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    cursor: pointer;
  }
  input[type="radio"]:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${(props) => `${props.theme.mainColor}`};
    transition: 0.1s ease-in;
  }
  input[type="radio"]:checked:before {
    width: 1.2rem;
    height: 1.2rem;
  }
  input[type="radio"]:checked {
    border-color: ${(props) => `${props.theme.mainColor}`};
  }

  .form-row {
    display: flex;
    flex-direction: column;
  }
  .text-field-wrap {
    flex: 1;
    margin-bottom: 4rem;
    & > label {
      font-size: 1.8rem;
      line-height: 140%;
      letter-spacing: -0.015em;
    }
  }
  .text-field-wrap:last-child {
    margin-bottom: 0;
  }

  .input-group-container {
    display: flex;
    flex-direction: column;
  }
  .input-group-name {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
    padding-bottom: 0.8rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    color: #121316;
    border-bottom: 1px solid #e5e5e5;
    svg {
      margin-right: 1rem;
    }
  }
  .p {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    font-size: 1.8rem;
    font-weight: 400;
  }
  .input-radio-list {
    display: flex;
    flex-flow: column nowrap;
  }
  .input-radio-item {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    label {
      position: relative;
      top: 0.1rem;
      margin-left: 0.8rem;
      font-size: 1.6rem;
      letter-spacing: -0.015em;
      color: #363740;
      cursor: pointer;
    }
  }
  .input-radio-item:last-child {
    margin-bottom: 0;
  }
  .input-radio-item--active {
    label {
      font-weight: 700;
    }
  }
  .checkbox-container {
    input[type="checkbox"] {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      min-width: 1.8rem;
      height: 1.8rem;
      border-radius: 0.15rem;
      margin: 0 0.8rem 0 0;
      border: 0.15rem solid #e5e5e5;
      outline: none;
      cursor: pointer;
    }
    input[type="checkbox"]:checked {
      background-color: ${(props) => `${props.theme.mainColor}`};
      border-color: ${(props) => `${props.theme.mainColor}`};
    }
    input[type="checkbox"]:checked::before {
      content: url(${markIcon});
      position: absolute;
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    label {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  .submit-button {
    max-width: 100%;
  }

  .mb-1 {
    margin-bottom: 4.8rem;
  }
  .mb-2 {
    margin-bottom: 7.6rem;
  }
`;

export const BackBtnStyle = styled.div`
  position: absolute;
  top: 30px;
  left: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.6rem;
`;

export const JoinOurTeamRoot = styled.div`
  form {
    input[type="checkbox"] {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      min-width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.2rem;
      margin: 0 1.2rem 0 0;
      border: 0.1rem solid #e5e5e5;
      outline: none;
      cursor: pointer;
      transition: 0.1s ease-in;
    }
    input[type="checkbox"]:checked {
      background-color: ${(props) => `${props.theme.mainColor}`};
      border-color: ${(props) => `${props.theme.mainColor}`};
    }
    input[type="checkbox"]:checked::before {
      content: url(${markIcon});
      position: absolute;
      display: block;
      width: 1.4rem;
      height: 1rem;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .copyright {
      margin-top: 1.2rem;
      font-size: 1.4rem;
      line-height: 140%;
      letter-spacing: -0.392308px;
      color: var(--font-tertiary-color);
      display: flex;
      align-items: center;
      span {
        font-size: 1.4rem;
        color: #c93030;
        cursor: pointer;
      }
    }
  }
  .form-row {
    margin-bottom: 3rem;
  }
`;

export const InterviewScreenRoot = styled.div`
  .calendly-inline-widget {
    position: fixed;
    top: 22rem;
    bottom: 0;
    right: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
    /* overflow-y: scroll; */
  }
`;

export const CdlScreenRoot = styled.div`
  & > p {
    margin-bottom: 2.8rem;
  }

  .info-img-wrap {
    width: 100%;
    margin-bottom: 4.8rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  button {
    margin-bottom: 1.6rem;
  }
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 45px;
  height: 45px;
  overflow: hidden;
  background: none;

  .ldio-535l3tci9g7 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.5);
    backface-visibility: hidden;
    /* // transform-origin: 0 0; */
  }
  .ldio-535l3tci9g7 div {
    box-sizing: content-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid ${(props) => `${props.theme.mainColor}`};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top-color: transparent;
    animation: ldio-535l3tci9g7 0.5s linear infinite;
  }

  @keyframes ldio-535l3tci9g7 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export const FileUploadContainer = styled.div`
  & > div:first-of-type {
    width: 100%;
    margin-bottom: 4.8rem;
    .spinner-wrap {
      display: flex;
      justify-content: center;
    }
    img {
      display: flex;
      width: 100%;
      height: auto;
    }
    .info-container {
      padding: 3.2rem 2.7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #eef1f4;
      .img-wrap {
        margin-bottom: 3.2rem;
        box-shadow: 0px 22px 29px rgba(0, 0, 0, 0.27),
          0px 9.19107px 12.1155px rgba(0, 0, 0, 0.194091),
          0px 4.91399px 6.47753px rgba(0, 0, 0, 0.160949),
          0px 2.75474px 3.63125px rgba(0, 0, 0, 0.135),
          0px 1.46302px 1.92853px rgba(0, 0, 0, 0.109051),
          0px 0.608796px 0.802504px rgba(0, 0, 0, 0.0759093);
        img {
          max-width: 18.7rem;
        }
      }
      h2 {
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 22px;
        letter-spacing: -0.02em;
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1.4rem;
        li {
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.8rem;
            rect {
              fill: ${(props) => `${props.theme.mainColor}`};
            }
          }
          span {
            font-size: 1.2rem;
            line-height: 22px;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
`;

export const SocialMediaScreenRoot = styled.div`
  .social-medial-list {
  }
  .social-media-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    padding: 2.5rem 1.6rem;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);
  }
  .social-media-item:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  .social-media-img {
  }
  .social-media-text {
    flex: 1;
    font-size: 1.4rem;
    letter-spacing: -0.015em;
  }
  .social-media-link {
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8.6rem;
    height: 3.8rem;
    font-weight: 700;
  }
`;

export const ProgressIndicatorContainer = styled.div`
  .indicator-wrap {
    width: 100%;
    height: 0.4rem;
    margin-bottom: 0.8rem;
    background-color: #e5e5e5;
    border-radius: 21px;
    overflow: hidden;
  }
  .indicator {
    height: 100%;
    background-color: ${(props) => `${props.theme.mainColor}`};
  }
  span {
    display: flex;
    justify-content: flex-end;
    font-size: 1.3rem;
    line-height: 120%;
    letter-spacing: -0.408px;
    color: #787883;
  }
`;

export const ExclusiveServiceRoot = styled.div`
  .mb-10 {
    margin-bottom: 1rem;
  }
  .mb-20 {
    margin-bottom: 2rem;
  }
  .mb-24 {
    margin-bottom: 2.4rem;
  }
  .mb-30 {
    margin-bottom: 3rem;
  }
  .mb-48 {
    margin-bottom: 4.8rem;
  }
  .mb-56 {
    margin-bottom: 5.6rem;
  }

  .text-17 {
    font-size: 1.7rem;
  }
  .text-20 {
    font-size: 2rem;
  }
  .text-26 {
    font-size: 2.6rem;
  }

  .text-green {
    color: #258d56;
  }

  .text-center {
    text-align: center;
  }

  .border-b-green {
    border-bottom: 2px solid #258d56;
  }

  .tt {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.27px;
    line-height: 120%;
  }

  .max-w-320 {
    margin-left: auto;
    margin-right: auto;
    max-width: 32rem;
  }

  .wrapper {
    position: relative;
    left: -1.6rem;
    width: calc(100% + 3.2rem);
    padding: 2.4rem 1.6rem 4rem;
    margin-bottom: -4rem;
    background-color: #ececec;
  }
  .card {
    overflow: hidden;
    padding: 2.8rem 1.6rem;
    background-color: #fff;
    border-radius: 1rem;
  }
  .card-box-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .card-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #afafaf;
    font-size: 2rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 3.2rem;
  }
  .card-desc {
    font-size: 1.6rem;
    line-height: 120%;
  }
  .images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem 1rem;
  }
  .list-item {
    color: #363740;
    font-size: 1.6rem;
    line-height: 120%;
    letter-spacing: -0.24px;
    list-style-type: disc;
    list-style-position: inside;
  }
  .list-item:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  .form-row {
    margin-bottom: 3rem;
  }
  .form-row:last-child {
    margin-bottom: 2.4rem;
  }

  .img-wrap {
    position: relative;
    margin-bottom: 1.6rem;
    left: -1.6rem;
    width: calc(100% + 3.2rem);

    img {
      width: 100%;
      height: auto;
    }
  }
  button {
    text-decoration: underline;
  }
`;

export const AnimatedRangeProfitRoot = styled.div`
  margin-bottom: 2.4rem;

  .range {
    position: relative;
    width: 100%;
    height: 2.4rem;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: 0.2rem;
    background-color: #ececec;
  }

  .text-green {
    color: #258d56;
  }
  .text-red {
    color: #c93030;
  }

  .bg-green {
    background-color: #258d56;
  }
  .bg-red {
    background-color: #c93030;
  }
  .bg-gray-10 {
    background-color: #ececec;
  }
  .bg-gray-20 {
    background-color: #e9e9e9;
  }
  .bg-gray-30 {
    background-color: #afafaf;
  }
  .font-600 {
    font-weight: 600;
  }

  .range-layer-1 {
    position: absolute;
    height: 100%;
    width: 35%;
    border-radius: 0.2rem;
    z-index: 2;
    animation: range-layer-1 12s infinite ease-in-out;
  }
  .range-layer-2 {
    position: absolute;
    height: 100%;
    width: 40%;
    border-radius: 0.2rem;
    z-index: 1;
    animation: range-layer-2 12s infinite ease-in-out;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
  }
  .flex-col {
    flex: 1;
  }
  .flex-col-left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .flex-col-centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .flex-col-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .rec {
    margin-bottom: 0.6rem;
    width: 0.4rem;
    height: 1.4rem;
    border-radius: 2px;
  }
  .flex-col__main-text {
    font-size: 1.8rem;
    font-weight: 700;
    span {
      font-size: 1rem;
    }
  }
  .flex-col__secondary-text {
    margin-bottom: 0.6rem;
    font-size: 1rem;
    letter-spacing: -0.45px;
  }
`;

export const AdvatangesRoot = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
  }
  .line {
    position: absolute;
    top: 4.7rem;
    left: 12.5%;
    width: 75%;
    height: 3px;
    background-color: #258d56;
    z-index: 1;
  }

  .adv-icon {
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .mark-icon {
    z-index: 2;
    width: 3rem;
    height: 3rem;
    border: 3px solid #258d56;
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .text {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
  }
`;
