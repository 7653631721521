import CustomButton from "components/CustomButton";
import youtubeImg from "assets/images/youtube-img.png";
import instagramImg from "assets/images/instagram-img.png";
import facebookImg from "assets/images/facebook-img.png";
import truckingImg from "assets/images/trucking42-img.png";
import { SocialMediaScreenRoot } from "styles/styles";

// import { RedditIcon, TiktokIcon, TwitterFilledIcon } from "assets/icons";
import twitterFilledIcon from "assets/icons/twitter-filled-icon.svg";
import redditIcon from "assets/icons/reddit-filled-icon.svg";
import tiktokIcon from "assets/icons/tiktok-icon.svg";

const media = [
  {
    text: "More then 100+ videos about trucking",
    imgHref: youtubeImg,
    imgAlt: "Youtube icon",
    linkHref: "https://www.youtube.com/channel/UCpeLFZRIfVrGA37BgNLB4Cw",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Join our group to get in touch with us",
    imgHref: instagramImg,
    imgAlt: "Instagram icon",
    linkHref: "https://www.instagram.com/trucking_42/",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: facebookImg,
    imgAlt: "Facebook icon",
    linkHref: "https://www.facebook.com/trucking42",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: twitterFilledIcon,
    imgAlt: "Twitter icon",
    linkHref: "https://twitter.com/Trucking_42",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: tiktokIcon,
    imgAlt: "Tiktok icon",
    linkHref: "https://www.tiktok.com/@trucking_42",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Follow us and see results of our company",
    imgHref: redditIcon,
    imgAlt: "reddit icon",
    linkHref: "https://www.reddit.com/user/Trucking-42",
    primaryLink: false,
    linkLabel: "Folow",
  },
  {
    text: "Visit our site and see results of our company",
    imgHref: truckingImg,
    imgAlt: "Trucking icon",
    linkHref: "https://trucking42.com",
    primaryLink: true,
    linkLabel: "Visit",
  },
];

const SocialMediaScreen = () => {
  return (
    <SocialMediaScreenRoot>
      <h2 className="title uppercase" style={{ marginBottom: "0.8rem" }}>
        Social media
      </h2>
      <p className="description" style={{ marginBottom: "4rem" }}>
        There are plenty of useful information we can share with you, please
        choose the best platform to follow us and always be updated.
      </p>
      <ul className="social-medial-list">
        {media.map(
          ({ text, imgHref, imgAlt, linkHref, primaryLink, linkLabel }) => (
            <li key={linkHref} className="social-media-item">
              <div
                className="social-media-icon"
                style={{
                  width: `${primaryLink ? "4.4rem" : "3.6rem"}`,
                  marginRight: `${primaryLink ? "1.2rem" : "2rem"}`,
                }}
              >
                <img src={imgHref} alt={imgAlt} />
              </div>
              <div className="social-media-text">{text}</div>
              <CustomButton
                as="a"
                href={linkHref}
                variant={
                  primaryLink ? "primary-contained-btn" : "dark-reverse-btn"
                }
                target="_blank"
                conditionalClasses="social-media-link"
              >
                {linkLabel}
              </CustomButton>
            </li>
          )
        )}
      </ul>
    </SocialMediaScreenRoot>
  );
};

export default SocialMediaScreen;
