import { useState } from "react";
import { useSubmitForm } from "hooks/useSubmitform";

import SendButton from "components/SendButton";

import formDataList from "./formDataList.json";
import { ExperienceIcon, TrailerIcon } from "assets/icons";
import { ShareExpScreenRoot } from "styles/styles";
import { deepEqual, isEmpty } from "utils";
import { getEmailSchema } from "validations";
import { Formik } from "formik";
import { TextField } from "components/FormFields";
import FormikErrorFocus from "formik-error-focus";

const icons = {
  experience: <ExperienceIcon />,
  preferredTrailer: <TrailerIcon />,
};

const ShareExpFirstScreen = ({ onNextStep, setCustomer, customer }) => {
  const [selectFields, setSelectedFields] = useState(
    customer?.shareExpFirstScreen?.selectFields ?? {}
  );
  const [{ loading, error }, submitData] = useSubmitForm({
    method: "updateLead",
    path: "/form/2",
    callback: () => onNextStep(),
  });

  const handleSubmit = ({ email }) => {
    const oldSelectFields = customer?.shareExpFirstScreen ?? {};
    const isEmptyOldFields = isEmpty(oldSelectFields);
    const equal = deepEqual(oldSelectFields, { email, selectFields });

    if (isEmptyOldFields || !equal) {
      setCustomer({ shareExpFirstScreen: { email, selectFields } });
      submitData({
        customerId: customer.customerId,
        step: "Share us your experience 1",
        email,
        ...selectFields,
      });
    } else {
      onNextStep();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedFields((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ShareExpScreenRoot>
      <h1 className="title uppercase">Tell Us About Yourself</h1>
      <p className="description" style={{ marginBottom: "1.8rem" }}>
        Answer the questions so that we understand your experience and all your
        expectations.
      </p>
      <Formik
        initialValues={{
          email: customer?.shareExpFirstScreen?.email ?? "",
        }}
        validationSchema={getEmailSchema()}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-row mb-2">
              <TextField
                name="email"
                placeholder="Enter your email address&#42;"
              />
            </div>

            {formDataList.map((item) => {
              const { title, name } = item;
              return (
                <div key={name} className="form-row mb-2">
                  <div className="input-group-container">
                    <p className="input-group-name">
                      {icons[name]} {title}
                    </p>
                    <div className="input-radio-list">
                      {item.options.map(({ radioName }) => {
                        const modRadioName = radioName.replace(/\s/g, "");
                        return (
                          <div
                            key={radioName}
                            className={`input-radio-item ${
                              selectFields?.[name] === radioName
                                ? "input-radio-item--active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={radioName}
                              name={name}
                              id={`${name}${modRadioName}`}
                              onChange={handleChange}
                              checked={selectFields?.[name] === radioName}
                            />
                            <label htmlFor={`${name}${modRadioName}`}>
                              {radioName}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

            <SendButton
              id="step-2"
              error={error}
              disabled={loading}
              type="submit"
              conditionalClasses={loading ? "inactive" : ""}
            >
              Continue
            </SendButton>
            <FormikErrorFocus
              offset={0}
              align="middle"
              // focusDelay={200}
              ease="linear"
              duration={250}
            />
          </form>
        )}
      </Formik>
    </ShareExpScreenRoot>
  );
};

export default ShareExpFirstScreen;
