import { createElement, useRef, useState } from "react";

import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";

import ProgressIndicator from "components/ProgressIndicator";
import BackBtn from "components/BackBtn/BackBtn";
// import IntroScreen from "screens/IntroScreen";
// import JoinOurTeamScreen from "screens/JoinOurTeamScreen";
import ShareExpFirstScreen from "screens/ShareExpFirstScreen";
import ShareExpSecondScreen from "screens/ShareExpSecondScreen";
import ShareExpThirdScreen from "screens/ShareExpThirdScreen";
import GetSolutionsScreen from "screens/GetSolutionsScreen";
import ExclusiveServiceScreen from "screens/ExclusiveServiceScreen";
// import InterviewScreen from "screens/InterviewScreen";
import CdlScreen from "screens/CdlScreen";
import SocialMediaScreen from "screens/SocialMediaScreen";
// import TermsAndConditions from "screens/TermsAndConditions";

import { Logo } from "assets/icons";
import { MainContainer } from "styles/app.style";

const comp = [
  // IntroScreen,
  ExclusiveServiceScreen,
  // JoinOurTeamScreen,
  ShareExpFirstScreen,
  ShareExpSecondScreen,
  ShareExpThirdScreen,
  GetSolutionsScreen,
  // InterviewScreen,
  CdlScreen,
  SocialMediaScreen,
  // TermsAndConditions,
];

const stepCount = comp.length;

function App() {
  const [step, setStep] = useState(0);
  const showProgressIndicator = step <= stepCount;
  const customerRef = useRef({
    fullName: "",
    phone: "",
    email: "",
    customerId: "",
  });

  const setCustomer = (values) => {
    customerRef.current = { ...customerRef.current, ...values };
  };
  const getCustomer = () => customerRef.current;
  const handleNextStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep + 1));
  };
  const handlePrevStep = (step) => {
    setStep((prevStep) => (typeof step === "number" ? step : prevStep - 1));
  };

  const stepProgress = step + 1;
  const backBtn = 1 < step ? <BackBtn onClick={handlePrevStep} /> : null;
  return (
    // <MainContainer className={step === 0 ? "extra-class" : null}>
    <MainContainer>
      <div className="relative-bl">
        {backBtn}
        <div className={"secondary-logo"}>
          <Logo />
        </div>
      </div>

      {showProgressIndicator && (
        <div style={{ marginBottom: "3.2rem" }}>
          <ProgressIndicator step={stepProgress} allSteps={stepCount} />
        </div>
      )}

      <AnimatePresence>
        <AnimatedScreen screenKey={step}>
          {createElement(comp[step], {
            onNextStep: handleNextStep,
            customer: getCustomer(),
            setCustomer: setCustomer,
          })}
        </AnimatedScreen>
      </AnimatePresence>
    </MainContainer>
  );
}

export default App;
