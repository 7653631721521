import { Formik } from "formik";
import { getCustomerFormSchema } from "validations";
import { useSubmitForm } from "hooks/useSubmitform";

import AnimatedRangeProfit from "components/AnimatedRangeProfit";
import SendButton from "components/SendButton";
import { PhoneField, TextField } from "components/FormFields";

import {
  ManIcon,
  FuelIcon,
  DocIcon,
  ServicesIcon,
  PetroIcon,
  FlyingIcon,
  PilotIcon,
  TaIcon,
  GroupLikeIcon,
  VuesaxIcon,
  TrailerRedIcon,
} from "assets/icons";
import truckerImgWebp from "assets/images/trucker.webp";
import truckerImg from "assets/images/trucker.png";

import { ExclusiveServiceRoot } from "styles/styles";
import Advantages from "components/Advantages";

const ExclusiveServiceScreen = ({ setCustomer, onNextStep }) => {
  const [{ loading, error }, handleSubmit] = useSubmitForm({
    method: "createLead",
    path: "/form/1",
    callback: (values) => {
      window.fbq("track", "Lead");
      window.dataLayer.push({ event: "formSubmit" });
      setCustomer(values);
      onNextStep();
    },
  });

  return (
    <ExclusiveServiceRoot>
      <h2 className="tt mb-20 text-17 text-center">
        Exclusive service for <br />
        <span className="border-b-green">owner operators & truck drivers</span>
      </h2>

      <div className="img-wrap">
        <picture>
          <source srcSet={truckerImgWebp} />
          <img src={truckerImg} alt="trucker" />
        </picture>
      </div>

      <h2 className="tt mb-24 text-20 text-center">
        Increase <br /> <span className="text-green">your weekly Income</span>
        <br />
        with our service
      </h2>

      <AnimatedRangeProfit />

      <div className="wrapper">
        <div className="card mb-24">
          <h3 className="card-title">
            <FuelIcon /> Fuel discounts
          </h3>

          <div className="images">
            <PetroIcon />
            <PilotIcon />
            <TaIcon />
            <FlyingIcon />
          </div>
        </div>

        <div className="card mb-24">
          <h3 className="card-title">
            <ServicesIcon /> 24/7 services
          </h3>
          <ul className="list">
            <li className="list-item">Booking Team</li>
            <li className="list-item">Breakdown assistance</li>
            <li className="list-item">Driver Care Department</li>
            <li className="list-item">Safety</li>
            <li className="list-item">HOS</li>
          </ul>
        </div>

        <div className="card mb-24">
          <h3 className="card-title">
            <ManIcon /> Professional dispatcher
          </h3>
          <ul className="list">
            <li className="list-item">5+ years experience Dispatch</li>
            <li className="list-item">broker accounts</li>
            <li className="list-item">regular freight</li>
            <li className="list-item">facility appointment check</li>
            <li className="list-item">
              90% Detentions and Layover Compensation Rate
            </li>
          </ul>
        </div>

        <div className="card mb-24">
          <h3 className="card-title">
            <GroupLikeIcon /> Open to negotiation
          </h3>
          <p className="card-desc">
            We prioritize working hand-in-hand with our Owner Operators, valuing
            their input and feedback in every decision.
          </p>
        </div>

        <div className="card mb-24">
          <h3 className="card-title">
            <DocIcon /> Full Transparency
          </h3>
          <p className="card-desc">
            Sending the rate confirmation from the broker to your email
          </p>
        </div>

        <div className="card mb-24">
          <h3 className="card-title">
            <TrailerRedIcon style={{ stroke: "#C93030" }} /> Any Trailer Type
          </h3>
          <ul className="list">
            <li className="list-item">Dry Van</li>
            <li className="list-item">Reefer</li>
            <li className="list-item">Open Deck</li>
            <li className="list-item">Power Only</li>
            <li className="list-item">Amazon</li>
          </ul>
        </div>

        <div className="card mb-48">
          <h3 className="card-title">
            <VuesaxIcon /> Plates Program
          </h3>
          <p className="card-desc">Plate Application Support for Drivers.</p>
        </div>

        <h1 className="tt text-26 text-center">Join our Best Team</h1>
        <h2 className="tt mb-10 text-20 text-green text-center">
          To Increase your weekly Income
        </h2>
        <p className="description mb-30 text-center max-w-320">
          Fill out the form and our specialists will promptly reach out to
          provide you with Exclusive Service.
        </p>

        <div className="card mb-48 card-box-shadow">
          <Advantages />
        </div>

        <Formik
          initialValues={{
            fullName: "",
            phone: "",
          }}
          validationSchema={getCustomerFormSchema()}
          onSubmit={handleSubmit}
        >
          {({ values, handleSubmit, setFieldValue, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <div className="card mb-56">
                <div className="form-row">
                  <TextField name="fullName" placeholder="Full name" />
                </div>

                <div className="form-row">
                  <PhoneField
                    name="phone"
                    value={values.phone}
                    placeholder="Phone"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <SendButton
                error={error}
                disabled={loading}
                type="submit"
                conditionalClasses={loading ? "inactive" : ""}
                id="step-1"
              >
                Send
              </SendButton>
            </form>
          )}
        </Formik>
      </div>
    </ExclusiveServiceRoot>
  );
};

export default ExclusiveServiceScreen;
