import * as Yup from "yup";

export const getCustomerFormSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .trim()
      .required("Name is required")
      .matches(/^[a-z-\s]+$/i, "Invalid name: only Latin letters allowed"),
    phone: Yup.string().required("Mobile phone is required"),
  });
};

export const getEmailSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Enter email address")
      .email("Invalid email"),
  });
};

export const getPhotoSchema = () => {
  return Yup.object().shape({
    photo: Yup.mixed()
      .required("Upload photo")
      .test("fileType", "Supported File Format: jpg, jpeg, png", function (value) {
        const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
        if (value && typeof value === "object") {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .test({
        message: "Photo too big, can't exceed 5mb",
        test: (value) => {
          if (value?.size) {
            return value.size < 5242880;
          }
          return true;
        },
      })
      .test({
        message: "Photo cannot be less than 100kb",
        test: (value) => {
          if (value?.size) {
            return value.size > 102400;
          }
          return true;
        },
      }),
  });
};
